import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import "animate.css";
import { ArrowRightCircle } from "react-bootstrap-icons";
import TrackVisibility from "react-on-screen";
import { useTranslation } from "react-i18next";

export const Banner = () => {
  const { t } = useTranslation();

  const intro = [t("intro1"), t("intro2"), t("intro3")];
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [t("intro1"), t("intro2"), t("intro3")];
  const period = 4000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 4);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleDownload = () => {
    const link1 = document.createElement("a");
    link1.href = "/Resume.pdf";
    link1.download = "JiangjianXie.pdf";
    document.body.appendChild(link1);
    link1.click();
    document.body.removeChild(link1);

    const link2 = document.createElement("a");
    link2.href = "/简历.pdf";
    link2.download = "谢江健.pdf";
    document.body.appendChild(link2);
    link2.click();
    document.body.removeChild(link2);
  };

  const handleGoogleDocs = () => {
    window.open(
      "https://docs.google.com/document/d/1v2lQgHbGeo03quRG7G_aGud5Ql_D80EMze1AkURcuBE/edit?usp=sharing",
      "_blank"
    );
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <span className="tagline">{t("welcome")}</span>
                  <h1>
                    {t("name")}{" "}
                    <div
                      className="txt-rotate"
                      dataPeriod="1000"
                      data-rotate={JSON.stringify(intro)}
                    >
                      <span className="wrap">{text}</span>
                    </div>
                  </h1>
                  <p>{t("intro4")}</p>
                  <div style={{ marginTop: "60px" }}>
                    <button onClick={handleGoogleDocs}>
                      {t("resume2")}
                      <ArrowRightCircle size={25} />
                    </button>
                    <button onClick={handleDownload}>
                      {t("resume1")}
                      <ArrowRightCircle size={25} />
                    </button>
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
