import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import "animate.css";
import TrackVisibility from "react-on-screen";
import weChat from "../assets/img/wechat.svg";
import tiktok from "../assets/img/tiktok.svg";
import github from "../assets/img/github.svg";
import bilibili from "../assets/img/bilibili.svg";
import wechatQR from "../assets/img/wechat-image.jpg";
import tiktokQR from "../assets/img/tiktokQR.jpg";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Popover, Image } from "react-bootstrap";

export const Contact = () => {
  const formInitialDetails = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const popoverWechat = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Image src={wechatQR} fluid />
      </Popover.Body>
    </Popover>
  );

  const popoverTiktok = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Image src={tiktokQR} fluid />
      </Popover.Body>
    </Popover>
  );

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formDetails.name || !formDetails.message) {
      setStatus({
        success: false,
        message: "Please fill in your Name and Message 😄",
      });
      return;
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    setButtonText("Sending...");
    let response = await fetch(`${apiUrl}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: "Message sent successfully" });
    } else {
      setStatus({
        succes: false,
        message: "Something went wrong, please try again later.",
      });
    }
  };
  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>{t("touch")}</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={12} className="px-1">
                        <input
                          type="text"
                          value={formDetails.name}
                          placeholder="Name *"
                          onChange={(e) => onFormUpdate("name", e.target.value)}
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="email"
                          value={formDetails.email}
                          placeholder="Email Address"
                          onChange={(e) =>
                            onFormUpdate("email", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="tel"
                          value={formDetails.phone}
                          placeholder="WeChat/Phone"
                          onChange={(e) =>
                            onFormUpdate("phone", e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea
                          rows="6"
                          value={formDetails.message}
                          placeholder="Message *"
                          onChange={(e) =>
                            onFormUpdate("message", e.target.value)
                          }
                        ></textarea>
                        {status.message && (
                          <Col>
                            <p
                              className={
                                status.success === false ? "danger" : "success"
                              }
                            >
                              {status.message}
                            </p>
                          </Col>
                        )}
                        <button type="submit">
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                    </Row>
                  </form>
                  <Row className="mt-3">
                    <Col className="social-icon navbar-text mt-5 contact-social-icon">
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popoverWechat}
                      >
                        <a href="#wechat">
                          <img src={weChat} alt="WeChat" />
                        </a>
                      </OverlayTrigger>
                      <a href="https://github.com/FlipW0rld" target="_blank">
                        <img src={github} alt="github" />
                      </a>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popoverTiktok}
                      >
                        <a href="#tiktok">
                          <img src={tiktok} alt="TikTok" />
                        </a>
                      </OverlayTrigger>
                      <a
                        href="https://space.bilibili.com/20579948?spm_id_from=333.1007.0.0"
                        target="_blank"
                      >
                        <img src={bilibili} alt="bilibili" />
                      </a>
                    </Col>
                  </Row>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
