import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import projImg1 from "../assets/img/SuFoodimg.png";
import projImg2 from "../assets/img/nextaiimg.png";
import { useTranslation } from "react-i18next";

export const ProjectDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const projectDetails = {
    sufood: {
      title: "Su Food",
      imgUrl: projImg1,
      techStack: (
        <ul>
          <li>Front-end (React, Ant Design)</li>
          <li>Back-end (Java, SpringBoot, Cache, Authorization)</li>
          <li>
            Deployments (REST APIs, Postgres SQL, docker, AWS APP Runner, AWS
            RDS, AWS ECR)
          </li>
        </ul>
      ),
      preview: "https://33ekhurke2.us-east-2.awsapprunner.com/",
      github: "https://github.com/FlipW0rld/SuFood",
      content: (
        <ul>
          <li>{t("sufoodline1")}</li>
          <li>{t("sufoodline2")}</li>
          <li>{t("sufoodline3")}</li>
          <li>{t("sufoodline4")}</li>
          <li>{t("sufoodline5")}</li>
          <li>{t("sufoodline6")}</li>
        </ul>
      ),
    },
    nextai: {
      title: "NextAI",
      imgUrl: projImg2,
      techStack: (
        <ul>
          <li>Front-end (React, Ant Design)</li>
          <li>Back-end (Express, Node.js)</li>
          <li>Deployments (OpenAI API, AWS APP Runner)</li>
        </ul>
      ),
      preview: "example.com",
      github: "https://github.com/FlipW0rld/NextAI",
      content: (
        <ul>
          <li>{t("nextailine1")}</li>
          <li>{t("nextailine2")}</li>
          <li>{t("nextailine3")}</li>
          <li>{t("nextailine4")}</li>
        </ul>
      ),
    },
    // Add more project details here...
  };

  const project = projectDetails[id];

  return (
    <Container className="project-detail">
      {project ? (
        <>
          <Row className="my-4">
            <Col md={12}>
              <h1>{project.title}</h1>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
              <Image src={project.imgUrl} alt={project.title} fluid />
            </Col>
            <Col md={6}>
              <h3>Tech Stack:</h3>
              <p>{project.techStack}</p>
              <h3>Preview:</h3>
              <p>
                <a
                  href={`${project.preview}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {project.preview}
                </a>
              </p>
              <h3>GitHub:</h3>
              <p>
                <a
                  href={`${project.github}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {project.github}
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Description:</h3>
              <p>{project.content}</p>
            </Col>
          </Row>
        </>
      ) : (
        <p>Project not found</p>
      )}
    </Container>
  );
};
