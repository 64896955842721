import React from "react";
import { useTranslation } from "react-i18next";
import Translation from "../assets/img/translation.svg";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "zh" : "en";
    i18n.changeLanguage(newLang);
  };

  return (
    <span className="Trans">
      <button
        onClick={toggleLanguage}
        aria-label="Toggle Language"
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <img src={Translation} alt="" className="translation" />
      </button>
    </span>
  );
};

export default LanguageSwitcher;
