import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      home: "Home",
      skills: "Skills",
      projects: "Projects",
      connect: "Let's Connect",

      welcome: "Welcome to my Portfolio",
      name: "Hi! I'm Jiangjian Xie",
      intro1: "Software Develop Engineer",
      intro2: "Web Designer",
      intro3: "Web Developer",
      intro4:
        "I was born in 2000 and graduated with a bachelor's degree in Electrical & Electronic Engineering from the University of Nottingham, and a master's degree in Electronic Computer Engineering from Boston University. I am looking for a job related to computer science.",

      resume1: "Resume (Download)",
      resume2: "Resume (Google Docs)",
      proejct: "Project",

      projectIntro:
        "I really enjoy the process of project development. When a project goes from an idea to a prototype and then becomes a finished product online, every step can learn a lot of new knowledge. People's aesthetic preferences for products are constantly evolving, which requires a constant stream of creative creativity to create shining points and retain the audience. Creativity is based on accumulation, and practical projects are the key to accumulation!",
      touch: "Get in touch",

      skill: "Skills",
      skillIntro: "",

      sufooddesc:"A Spring and Hibernate based online food ordering system",
      sufoodline1:"Architected the project with a clear separation of concerns into controller, service, and repository layers, utilizing dependency injection for enhanced maintainability.",
      sufoodline2:"Developed CRUD REST APIs using Spring Controllers, encompassing functionalities such as registration, menu searching, ordering, and checkout.",
      sufoodline3:"Leveraged Spring Data JDBC and repositories to interface with a PostgreSQL database hosted on AWS RDS, handling data related to menus, restaurants, and more.",
      sufoodline4:"Implemented application authentication via Spring Security, employing session-based authentication mechanisms.",
      sufoodline5:"Constructed the frontend using ReactJS and Ant Design, providing users with the ability to seamlessly add items to their shopping cart and place orders.",
      sufoodline6:"Containerized the build and successfully deployed it to AWS App Runner for streamlined scalability and accessibility.",

      nextaidesc:"Full-Stack Web-Based Q&A AI Agent for PDF Document Queries",
      nextailine1:"Engineered an interactive conversational UI leveraging React and Ant Design, enabling users to effortlessly upload and interact with PDF documents in real-time ",
      nextailine2:"Architected and implemented RESTful APIs via Express and Node.js, optimized for high-performance request handling ",
      nextailine3:"Utilized a memory vector store to cache generated embeddings for efficient retrieval",
      nextailine4:"Integrated OpenAI's GPT-3.5 Turbo API and Langchain technologies, resulting in a sophisticated AI Agent capable of document loading, splitting, storage, retrieval, and output functionalities",
    },
  },
  zh: {
    translation: {
      home: "首页　　",
      skills: "技能　　",
      projects: "项目　　",
      connect: "联系我",

      welcome: "欢迎来到我的个人主页",
      name: "嗨！我是谢江健",
      intro1: "软件开发工程师",
      intro2: "网页设计师",
      intro3: "网页开发者",
      intro4:
        "我出生于2000年，本科毕业于诺丁汉大学电气工程及其自动化专业，研究生毕业于波士顿大学电子计算机工程专业。我正在寻找一份计算机方向相关的工作。",

      resume1: "简历 (下载）",
      resume2: "简历（谷歌文档）",

      project: "项目",
      projectIntro:
        "我很享受项目开发的过程，当一个项目从想法到雏形再成为成品上线，每一个过程都可以学习到非常多的新知识。人们对于产品的审美是会更新迭代的，这需要源源不断的创意制造闪光点来留住人群。创意基于积累，而实操项目是积累的关键！",
      touch: "联系",

      skill: "技能",
      skillIntro: "",

      sufooddesc:"基于Spring和Hibernate的在线食品订购系统",
      sufoodline1:"构建项目时，将关注点明确分离为控制器、服务和存储库层，利用依赖项注入来增强可维护性",
      sufoodline2:"使用 Spring Controller 开发 CRUD 操作并构建 RESTful APIs，包括注册、菜单搜索、订购和结账等功能",
      sufoodline3:"利用 Spring Data JDBC 和存储库与 AWS RDS 上托管的 PostgreSQL 数据库进行交互，处理与菜单、餐厅等相关的数据",
      sufoodline4:"通过 Spring Security 实现应用程序身份验证，采用基于会话的身份验证 ",
      sufoodline5:"使用 React 和 Ant Design 构建前端，使用户能够无缝地将商品添加到购物车并下订单",
      sufoodline6:"将应用程序容器化并成功将其部署到 AWS App Runner，以简化可扩展性和可访问性",

      nextaidesc:"用于PDF文档查询的全栈网络问答AI助理",
      nextailine1:"利用 React 和 Ant Design 设计了交互式对话 UI，使用户能够轻松上传 PDF 文档并与实时交互 ",
      nextailine2:"过 Express 和 Node.js 架构和实施 RESTful API，针对高性能请求处理进行了优化 ",
      nextailine3:"利用内存向量存储来缓存生成的嵌入，以实现高效检索",
      nextailine4:"集成OpenAI的gpt-3.5-turbo API和Langchain技术，形成一个复杂的AI Agent，能够实现文档加载、分割、存储、检索和输出功能",
    },
  },
};

i18n
  .use(initReactI18next) // 通过initReactI18next传递i18n实例
  .use(LanguageDetector) // 自动检测浏览器语言
  .init({
    resources,
    fallbackLng: "en", // 设置默认语言
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false, // 不需要对输出进行HTML转义
    },
  });

export default i18n;
